.ant-tabs-nav-list {
	width: 100%;
}
.ant-tabs-tab {
	width: 50%;
	font-size: 20px;
	font-weight: bold;
	display: flex;
	justify-content: center;
}
.row-in-tab {
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
}

@media screen and (max-width: 700px) {
	.row-in-tab {
		flex-direction: column !important;
	}
}

.restored-by-tabpane,
.updated-by-tabpane,
.deleted-by-tabpane {
	padding: 10px !important;
	height: 60vh;
	overflow: scroll;
}

.tab-edit-btn {
	width: 100%;
	margin: 25px 0 15px 0;
}

.tab-username {
	color: #00afef;
}

.tab-date {
	font-weight: bold !important;
	color: #718096 !important;
}
.tab-title {
	color: #4a5568 !important;
}
.tab-content-width {
	width: 95%;
	margin: auto;
}
.no-data-found-image-parent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.no-data-found-image {
	max-width: 400px;
	max-height: 400px;
}
.no-history-present-text {
	font-weight: bold;
	font-size: 20px;
	margin: 15px 0;
	color: #718096;
}
