.ant-checkbox-group {
	line-height: 2.5715 !important;
}
.templateFormResourcesGroup {
	display: flex;
	/* align-items: center; */
	/* justify-content: ""; */
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 20px;

	/* background-color: aqua; */
}

.templateFormTextGroupChild {
	/* background-color: rgb(128, 43, 43); */
	width: 400px;
	/* max-height: 100;
  overflow-x: scroll !important; */
}
@media only screen and (max-width: 940px) {
	.templateFormResourcesGroup {
		flex-direction: column;
	}
	.templateFormTextGroupChild {
		width: 100%;
	}
}
