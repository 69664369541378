.accessoryFormTextGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.accessoryFormTextGroupChild {
	width: 300px  !important;
}

@media only screen and (max-width: 994px) {
	.accessoryFormTextGroup {
		flex-direction: column;
	}
	.accessoryFormTextGroupChild {
		width: 100% !important;
	}
}
