:root {
	--primary: #e2e8f0;
	--primaryforbutton: #00aff0;
	--secondary: #d2d3d5;
}
.ant-menu {
	margin-top: 1rem !important;
}
.ant-menu::-webkit-scrollbar {
	opacity: 0;
}
.ant-card {
	border-radius: 1rem !important;
	margin-top: 2rem !important;
}
.ant-table-thead > tr > th {
	background-color: white !important;
}
.ant-table-container {
	padding: 1rem;
}
.ant-table-footer {
	text-align: center;
	font-weight: bold;
	font-size: 1rem;
	color: #8a8c91 !important;
	background-color: white;
}
.ant-notification-notice {
	border-radius: 0.6rem;
}
.button-style-to-right {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-end;
	margin: 1.8rem 0rem;
}
.ant-message-notice-content {
	border-radius: 0.6rem !important;
}
.download-btn {
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 10px !important;
	background-color: #90cdf4 !important;
	border: none !important;
	color: #2c5282 !important;
}
.ant-btn-primary {
	border-radius: 10px !important;
	background-color: #90cdf4 !important;
	border: none !important;
	color: #2c5282 !important;
}

.ant-btn-sm {
	border-radius: 10px !important;
	background-color: #90cdf4 !important;
	border: none !important;
}

.enqiury-detail-card {
	filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
	margin: 15px !important;
}

.ant-select-dropdown {
	background-color: #e2e8f0 !important;
}
.ant-select-item-option-active {
	background-color: #e2e8f0 !important;
}
.ant-select-item-option {
	background-color: #e2e8f0 !important;
}
.width-for-sider {
	min-width: 250px !important;
}
#view-model-box {
	max-height: 80vh;
	overflow: scroll;
}
.ant-form-item-label > label {
	font-size: 18px !important;
}
.ant-btn-dangerous {
	background: #ff4d4f !important;
	color: white !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: rgb(0, 175, 239) !important;
	background-color: rgb(0, 175, 239) !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-icon-dot {
	background: rgb(0, 175, 239) !important;
	color: rgb(0, 175, 239) !important;
}

.ant-steps-item-tail::after {
	background: rgb(0, 175, 239) !important;
}
