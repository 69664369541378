.ant-btn-primary > a {
	font-weight: bold;
}
.ant-select-selector {
	border-radius: 10px !important;
}
.ant-btn-dangerous {
	background-color: #fed7d7 !important;
	color: #e53e3e !important;
}
