.fallbackParent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 700px;
}
.headingStyles {
	color: red !important;
}
@media only screen and (max-width: 700px) {
	.fallbackParent {
		height: 400px;
	}
}
