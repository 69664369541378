.button-box {
	display: flex;
	justify-content: center;
}

.gallery-images {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.image-holder-container {
	margin: 10px;
}

.form-container {
	min-height: 100%;
	width: 100%;
}

.form-card {
	width: 100%;
	padding: 40px !important;
}

.error-alert {
	margin-top: 20px;
	margin-bottom: 20px;
}

.form-form-button {
	min-width: 100%;
}

#form-container {
	display: block !important;
}

.ant-form-item-label {
	display: flex !important;
	width: 150px;
}

.ant-form-item-explain.ant-form-item-explain-error {
	margin-bottom: 10px;
	margin: 10px;
	padding: 0px;
}

.ant-card-body {
	padding: 0 !important;
}

.ant-card-bordered {
	border: none !important;
}

h1.ant-typography {
	width: 100% !important;
	text-align: center !important;
}

.update-or-add-title {
	margin: 2rem;
}
@media only screen and (max-width: 700px) {
	.gallery-images {
		justify-content: center;
	}
}
@media only screen and (max-width: 400px) {
	.update-or-add-title {
		font-size: 30px !important;
		margin: 2rem;
	}
}
