.modelFormTextGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}
.technicalSpecsParent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 15px 0;
}
.technicalSpecsChild {
	width: 48%;
}
.addSubButtonForTechnicalSpec {
	display: inline;
}
.modelFormTextGroupChild {
	width: 200px;
}
@media only screen and (max-width: 450px) {
	.technicalSpecsParent {
		flex-direction: column;
	}
	.technicalSpecsChild {
		width: 100%;
		margin: 10px 0;
	}
	.addSubButtonForTechnicalSpec {
		display: flex;
		margin-top: 10px;
		margin-bottom: -15px;
		justify-content: space-between;
	}
}

@media only screen and (max-width: 940px) {
	.modelFormTextGroup {
		flex-direction: column;
	}
	.modelFormTextGroupChild {
		width: 100%;
	}
}
