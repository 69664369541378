.buttonStyleToRight {
	display: flex;
	width: 100%;
	flex-direction: column;
	margin: 1.8rem 0rem;
}
.buttonStyleToRightForm {
	display: flex;
	align-items: center;
	width: 100%;
}
.searchButtonStyle {
	margin: 5px 10px;
	width: 100px;
	border-radius: 10px !important;
	background-color: #90cdf4 !important;
	border: none;
	color: #2c5282 !important;
}
.selectListStyle {
	width: 100%;
	margin: 10px 0px !important;
	border-radius: 10px;
}
.selectListStyle > div {
	background-color: #90cdf4 !important;
	border: none;
	color: #2c5282 !important;
}

.selectListStyle > div > span {
	color: #2c5282 !important;
	font-weight: bold;
}
.btnHolder {
	display: flex;
	justify-content: flex-end;
}
.exportBtn {
	width: 100px;
	margin: 5px 0px;
	border-radius: 10px !important;
	background-color: #90cdf4 !important;
	border: none !important;
	color: #2c5282 !important;
}
