.ant-table-cell {
	background-color: white;
	font-size: 0.8rem !important;
}

.table {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	flex-direction: column;
	flex: 1;
}
.ant-table-row {
	color: #8a8c91 !important;
	font-weight: 600;
}
.ant-table-tbody > tr > td {
	border: none !important;
	font-size: 16px;
}
.ant-table-tbody > :not(:first-child) > {
	padding: 14px 16px !important;
}
/* Assign Radius to table */
.ant-table {
	border-radius: 1.2rem !important;
	overflow: hidden !important;
}
.ant-table-thead > tr > th {
	font-weight: bold !important;
	color: #5a5660 !important;
	font-size: 17px;
}
/* Customizing Button Of Pagination */
.ant-pagination > li {
	border-radius: 2rem !important;
}
.ant-pagination-item-link {
	border-radius: 2rem !important;
}
div[role="alert"] {
	white-space: nowrap;
	overflow: hidden;
}
