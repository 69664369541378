.drawer-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	outline: none;
	border: none;
	background-color: white;
	cursor: pointer;
}

.root-div {
	/*  margin: 0px;
    padding: -10px; */
	/* position: relative; 
    top: -25px; */
	z-index: 20;
	overflow-y: hidden;
}

.ant-drawer-body {
	padding-top: 0px !important;
}

.subroot-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px;
}

.subroot-button {
	margin-left: -10px;
	font-size: 25px;
}

.ant-menu-item::after {
	border-right: none;
}
.ant-menu-item-selected::after {
	border-right: none;
}

.modal-footer {
	display: flex;
	justify-content: center;
	background-color: #e2e8f0 !important;
	color: black !important;
}
.user-feild-mobile {
	display: block;
	height: 60px;
	width: 100%;
	filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
	background-color: #e2e8f0;
}
.user-name-mobile {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 5px;
	color: #2d3748;
	font-weight: bold !important;
}
.user-companyname-mobile{
	margin-top: -10px;
	margin-left: 10px;
	font-size: 12px;
	margin-right: 10px;
	color: #2d3748;
}