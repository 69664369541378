.layout-container {
	min-height: 100vh;
	overflow-y: hidden;
}

.ant-layout-header {
	background: #e2e8f0 !important;
	height: 90px !important;
}

.image-container {
	width: 11rem;
	margin-left: -2.7rem;
}
.site-header-layout-background {
	display: flex;
	padding: 12px;
	background-color: white !important;
	justify-content: flex-end;
}

.site-content {
	margin: 0 16px;
}

.site-layout-background {
	min-height: 82vh;
}

.footer-text {
	text-align: center;
}

.header-div-customized {
	height: 4.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@media screen and (max-width: 900px) {
	.header-div-customized {
		justify-content: space-between;
		padding: 0px 10px;
	}
	.image-container {
		width: 10rem;
		margin-left: 10px;
	}
}
.ant-menu-item::after {
	border-right: none !important;
	display: none !important;
}
/* After Redesigning CSS */
.header-user-div-customized {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding: 20px;
	background: #e2e8f0 !important;
}

.user-logo-and-name-container {
	display: flex;
	align-items: center;
	border-radius: 5rem;
	background: #cbd5e0;
	padding: 5px 20px;
	border-radius: 2rem;
	width: fit-content;
	height: fit-content;
	cursor: pointer !important;
}

.user-logo-and-name-and-logout {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.user-logo-and-name-button {
	margin-left: -5px;
	color: #2d3748 !important;
	font-weight: bold !important;
}

.user-logo-and-name-image {
	padding-top: 5px;
	border-radius: 100% !important;
}
.user-logout-button {
	border-radius: 100% !important;
	background: #cbd5e0 !important;
	margin-left: 30px;
	color: #e53e3e !important;
	font-weight: bold !important;
}
.user-logout-button:hover {
	color: black !important;
}
/* Mobile View CSS After Redesigning*/
@media screen and (max-width: 900px) {
	.header-user-div-customized {
		justify-content: space-between !important;
		padding: 0px 20px;
		background: white !important;
	}
	.user-feild {
		display: none !important;
	}
}
@media screen and (max-width: 600px) {
	.user-logo-and-name-container {
		display: none !important;
	}

	.user-logout-button {
		margin: 0px;
	}
}
/* Adding Color For Redesign of Admin Panel */
.basic-layout-menu {
	background-color: var(--primary) !important;
	height: 700px;
	/* overflow-y: scroll !important; */
	overflow-y: scroll;
	overflow-x: hidden;
}
.ant-layout-sider {
	background-color: var(--primary) !important;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-item-selected {
	color: var(--primaryforbutton) !important;
	font-weight: bold !important;
	background-color: var(--primary) !important;
}
.ant-menu-submenu-selected {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-submenu {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-submenu-title:hover {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-submenu-active {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-submenu-arrow {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-sub.ant-menu-inline {
	background-color: var(--primary) !important;
}
.ant-menu-inline {
	width: 100.4% !important;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	background-color: var(--primary) !important;
}
.ant-menu-inline {
	background-color: var(--primary) !important;
}
.ant-menu {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-item a {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-menu-item-active {
	color: #2d3748;
	font-weight: bold !important;
}
.ant-image-mask {
	display: none !important;
	/* to hide Preview  in image */
}

.basiclayout-margin {
	margin: 2.3rem 1rem 0rem 1rem;
}
@media screen and (max-width: 500px) {
	.basiclayout-margin {
		margin: 2rem 0rem;
	}
}
@media screen and (max-width: 900px) {
	.root-div {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: hidden;
	}
	.ant-menu-inline {
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	.ant-drawer-body {
		padding: 0px !important;
	}
	.subroot-div {
		padding: 0px 10px;
	}
}

.user-feild {
	display: block;
	height: 60px;
	width: auto;
	filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
	background-color: #e2e8f0;
}
.user-name {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 5px;
	color: #2d3748;
	font-weight: bold !important;
}
.user-companyname {
	margin-top: -10px;
	margin-left: 10px;
	font-size: 12px;
	margin-right: 10px;
	color: #2d3748;
}
