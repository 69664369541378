.button-box {
	display: flex;
	justify-content: center;
}

.gallery-images {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.image-holder-container {
	margin: 10px;
}
.job-detail-card {
	padding: 10px !important;
	margin: 10px !important;
}
@media only screen and (max-width: 700px) {
	.gallery-images {
		justify-content: center;
	}
}
.ant-list-items {
	padding: 0 20px !important;
}

.ant-form-item-label {
	/* for select type in adding media form getting width full */
	width: 100%;
}
@media only screen and (max-width: 1250px) {
	.gallery-update-add-button {
		margin-top: 1.5rem !important;
	}
}
