.subAssemblyFormTextGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.subAssemblyFormTextGroupChild {
	width: 300px !important;
}

@media only screen and (max-width: 994px) {
	.subAssemblyFormTextGroup {
		flex-direction: column;
	}
	.subAssemblyFormTextGroupChild {
		width: 100% !important;
	}
}
