.userFormTextGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}
.userFormTextGroupChild {
	width: 300px;
}
.userFormInputGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	margin-top: -20px;
}
.userFormInputChild {
	width: 48%;
}
.userFormInputChildWidht {
	min-width: 48%;
	width: 100%;
	margin-right: 15px;
}
.adderres {
	min-height: 120px !important;
	margin-top: 20px !important;
}
.userDistrict {
	margin-top: 10px;
}
.userTemplate {
	margin-top: 10px;
}
.godownAddress {
	min-height: 120px !important;
	margin-top: 25px !important;
	min-width: 48%;
	width: 100%;
	margin-right: 15px;
}

.updateMobile {
	min-height: 120px !important;
	margin-top: 25px !important;
	min-width: 48%;
	width: 100%;
	margin-right: 15px;
}
@media only screen and (max-width: 940px) {
	.userFormTextGroup {
		flex-direction: column;
	}
	.userFormInputGroup {
		flex-direction: column;
	}
	.userFormTextGroupChild {
		width: 100%;
		margin-top: -10px;
	}
	.userFormInputChild {
		width: 100%;
	}
	.responsive {
		margin-top: 15px;
	}
	.adderres {
		margin-top: -20px !important;
		margin-bottom: 20px !important;
	}
	.userDistrict {
		margin-top: -5px !important;
	}
	.userTemplate {
		margin-top: -5px !important;
	}
	.updateMobile {
		margin-top: -30px !important;
	}
}
