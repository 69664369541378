.parent-box-of-pagenotfound {
	display: flex;
	height: 70vh;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.box-of-text-not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.go-back {
	border: none;
	background: #00aff0 !important;
	border-radius: 1rem !important;
	color: black !important;
	font-weight: bold;
	min-width: 120px;
}
.go-back:hover {
	background: #00aff0 !important;
	color: black !important;
}
@media screen and (max-width: 700px) {
	.parent-box-of-pagenotfound {
		flex-direction: column;
		height: 100%;
	}
}
