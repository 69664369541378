.login-container {
	min-height: 100vh;
	display: flex !important;
	flex-direction: column !important;
	align-items: center;
	justify-content: center;
	background-color: #e2e8f0 !important;
}

.login-card {
	filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
	padding: 40px !important;
	width: 500px;
	/* height: 400px; */
}

.error-alert {
	margin-top: 20px;
	margin-bottom: 20px;
}

.login-form-button {
	margin-top: 10px;
	min-width: 100%;
	height: 50px !important;
	border-radius: 5px !important;
	border: none;
	color: white;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
}

.ant-form-item-explain.ant-form-item-explain-error {
	margin-bottom: 10px;
	margin: 10px;
}
.logo-and-text-in-login-form {
	margin-top: -100px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.logo-image-container {
	display: inline-block;
	height: 150px;
	width: 250px;
	margin-bottom: -20px;
}
.sign-in-title {
	font-weight: bold !important;
}
.ant-form-item-required {
	font-size: 18px !important;
}

.ant-card {
	border-radius: 5px !important;
}
.ant-form-item-control {
	border-radius: 5px !important;
}
.ant-input {
	height: 40px !important;
	border-radius: 5px !important;
}
.forgot-password {
	color: #1296ea !important;
}
@media only screen and (max-width: 700px) {
	.sign-in-title {
		font-size: 22px !important;
	}
}
.ant-btn {
	font-weight: bold !important;
}
@media only screen and (max-width: 580px) {
	.sign-in-title {
		font-size: 22px !important;
	}
	.login-card {
		width: 450px;
	}
}
@media only screen and (max-width: 480px) {
	.login-card {
		width: 90vw;
	}
}

@media only screen and (max-width: 400px) {
	.sign-in-title {
		font-size: 18px !important;
	}
	.login-card {
		width: 100%;
	}
}
.ant-input-affix-wrapper {
	padding: 0px 11px !important;
	border-radius: 5px !important;
}
