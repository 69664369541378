.upload-root-container {
	border: dashed 1px #000;
	padding: 20px;
	display: inline-block;
	width: 100%;
	text-align: center;
}

.upload-area {
	display: flex;
	flex-direction: column;
}

.icon-container {
	font-size: 200%;
	margin-bottom: 10px;
}

.media-container {
	max-width: 250px !important;
	max-height: 250px !important;
}

.file-holder {
	margin: 10px;
	padding: 10px;
	display: inline-flex;
	flex-wrap: wrap;
	width: 100%;
}
