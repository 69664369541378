.implementFormTextGroup {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.implementFormTextGroupChild {
	width: 300px !important;
}

@media only screen and (max-width: 994px) {
	.implementFormTextGroup {
		flex-direction: column;
	}
	.implementFormTextGroupChild {
		width: 100% !important;
	}
}
